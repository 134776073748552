<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" outlined color="#6D59E8">
        <v-icon left>mdi-cloud-upload</v-icon>
        Importar
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Importar {{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-model="file"
          label="Selecionar arquivo"
          prepend-icon="mdi-file-upload"
          accept=".csv"
          color="primary"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="dialog = false">
          <v-icon left> mdi-close </v-icon>
          Cancelar
        </v-btn>

        <v-btn color="success" @click="importar">
          <v-icon left> mdi-content-save </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import { mapGetters } from "vuex";
export default {
  name: "importar-csv",
  data() {
    return {
      file: null,
      dialog: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "Default",
    },
    upload: {
      type: Function,
      required: true,
      default: function (e) {
        console.log(e);
      },
    },
  },
  methods: {
    onFileChange(e) {
      console.log(e);
    },
    async importar() {
      if (this.file) {
        store.commit(CHANGE_PROGRESS, true);
        await this.upload(this.file)
          .then(() => {
            store.commit(CHANGE_PROGRESS, false);
            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Adicionado com sucesso";
          })
          .catch(({ response }) => {
            store.commit(CHANGE_PROGRESS, false);
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao importar.";
            if (typeof response.data.message !== typeof undefined) {
              this.snackbar.text = "Erro ao importar: " + response.data.message;
            }
          });
      }
      this.dialog = false;
    },
    openModal() {},
    CloseModal() {},
  },
  computed: {
    ...mapGetters(["snackbar"]),
  },
};
</script>
